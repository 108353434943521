@font-face {
  font-family: "iconfont"; /* Project id 4571642 */
  src: url('iconfont.woff2?t=1730026401314') format('woff2'),
       url('iconfont.woff?t=1730026401314') format('woff'),
       url('iconfont.ttf?t=1730026401314') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-location:before {
  content: "\e790";
}

.icon-mobile:before {
  content: "\e819";
}

.icon-email-fill:before {
  content: "\e856";
}

.icon-task:before {
  content: "\e651";
}

.icon-online:before {
  content: "\e601";
}

.icon-offline:before {
  content: "\e603";
}

.icon-a-Overcastsky:before {
  content: "\e60c";
}

.icon-sunny-light:before {
  content: "\e60d";
}

.icon-sunny-night:before {
  content: "\e60e";
}

.icon-cloudy-light:before {
  content: "\e60f";
}

.icon-cloudy-night:before {
  content: "\e610";
}

.icon-a-moderaterain:before {
  content: "\e612";
}

.icon-rainstorm:before {
  content: "\e613";
}

.icon-shower-light:before {
  content: "\e614";
}

.icon-a-smallrain:before {
  content: "\e615";
}

.icon-shower-night:before {
  content: "\e616";
}

.icon-a-heavyrain:before {
  content: "\e617";
}

.icon-a-Lightsnow:before {
  content: "\e619";
}

.icon-thundershower:before {
  content: "\e61a";
}

.icon-sleet:before {
  content: "\e61b";
}

.icon-a-Moderatesnow:before {
  content: "\e61c";
}

.icon-a-Heavysnow:before {
  content: "\e61d";
}

.icon-blizzard:before {
  content: "\e61e";
}

.icon-hailstone:before {
  content: "\e61f";
}

.icon-haze:before {
  content: "\e620";
}

.icon-a-Sandblowing:before {
  content: "\e621";
}

.icon-a-Thefog:before {
  content: "\e622";
}

.icon-snow:before {
  content: "\e623";
}

.icon-user:before {
  content: "\e60a";
}

.icon-data:before {
  content: "\e611";
}

.icon-monitor:before {
  content: "\e71f";
}

.icon-alarm:before {
  content: "\e600";
}

.icon-notification:before {
  content: "\e679";
}

.icon-device-group:before {
  content: "\e618";
}

.icon-si-glyph-light-alarm:before {
  content: "\e655";
}

.icon-icon-devicegroup-outlined:before {
  content: "\e634";
}

.icon-Dashboard:before {
  content: "\e633";
}

.icon-device-group-list:before {
  content: "\eaef";
}

.icon-Firmware:before {
  content: "\e602";
}

